/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import clsx from "clsx";
import { Loading, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid, Box, Typography, Button, Tooltip, Chip } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { TableHead, TableFooter, TableRow, TableCell } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { TableHead as MuiTableHead } from "mui-datatables";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
import PopupTable from "components/PopupTable";
import { createArInvoiceDetail } from "services/accountReceivable";
import { getActiveDimListByModuleName } from "services/dimension";
import { addDays } from "date-fns";
import DialogFolio from "./DialogFolio";
import Model from "models/arInvoice";
import ModelDetail from "models/arInvoiceDetail";
import SnackbarUtils from "utils/SnackbarUtils";

const Create = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const { basePath, formFields, formFieldsDetail, formFieldsTax1, formFieldsTax2, lookupList } = props;
  const redirect = useRedirect();

  const { settingAll, NumberFormat, ToNumber, FindTaxAmount } = useContext(GblContext);
  const { SettingSystem, SettingAr } = settingAll;
  const [loading, setLoading] = useState(true);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [autoGroupNo, setAutoGroupNo] = useState(1);
  const [initData, setInitData] = useStateWithCallbackLazy(Model);
  const [initNewRow, setInitNewRow] = useStateWithCallbackLazy(ModelDetail);
  const [summaryValue, setSummaryValue] = useState({
    netAmt: 0,
    taxAmt1: 0,
    taxAmt2: 0,
    total: 0,
    baseNetAmt: 0,
    baseTaxAmt1: 0,
    baseTaxAmt2: 0,
    baseTotal: 0,
  });
  const [showAdd, setShowAdd] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [openDialogFolio, setOpenDialogFolio] = useState(false);
  const [originalData, setOriginalData] = useState();
  const methods = useForm({ defaultValues: initData });

  const { handleSubmit, getValues, setValue, reset } = methods;

  useEffect(() => {
    initData.Detail = [];
    initData.CurrCode = SettingSystem.DefaultCurrencyCode;
    initData.CurrRate = SettingSystem.DefaultCurrencyRate;
    initData.InvPrefix = SettingAr.InvPrefix;
    initNewRow.Unit = SettingAr.DefaultUnit;
    const cloneModel = _.cloneDeep(initData);
    setInitData(cloneModel);
    reset(cloneModel);
  }, []);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();
    setBtnLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    //ConvertToNumber
    initData.Detail.forEach((item) => {
      item.Qty = ToNumber(item.Qty);
      item.NetAmt = ToNumber(item.NetAmt);
      item.NetBaseAmt = ToNumber(item.NetBaseAmt);
      item.Price = ToNumber(item.Price);
      item.TaxAmt1 = ToNumber(item.TaxAmt1);
      item.TaxAmt2 = ToNumber(item.TaxAmt2);
      item.TaxBaseAmt1 = ToNumber(item.TaxBaseAmt1);
      item.TaxBaseAmt2 = ToNumber(item.TaxBaseAmt2);
      item.TotalAmt = ToNumber(item.TotalAmt);
      item.TotalBaseAmt = ToNumber(item.TotalBaseAmt);
      item.Unpaid = ToNumber(item.Unpaid);
    });

    const sumTotal = initData.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.TotalAmt);
      return NumberFormat(s ?? 0);
    }, 0);

    const sumUnpaid = initData.Detail.reduce((accu, item) => {
      const s = ToNumber(accu) + ToNumber(item.Unpaid);
      return NumberFormat(s ?? 0);
    }, 0);

    setInitData(
      (state) => ({
        ...state,
        ...values,
        CurrRate: ToNumber(values.CurrRate),
        InvhTotal: ToNumber(sumTotal),
        InvhUnpaid: ToNumber(sumUnpaid),
      }),
      (nextState) => Save(nextState)
    );
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Void", disabled: true },
    { name: "Copy", disabled: true },
    { name: "Print", disabled: true },
  ];

  const fetchDimListByModule = useCallback(async (mounted) => {
    if (originalData) {
      setInitNewRow((state) => ({
        ...state,
        DimList: {
          Dim: originalData,
        },
      }));
    } else {
      const { Data } = await getActiveDimListByModuleName(10, 1, "AR-ID");

      const original = _.cloneDeep(Data);
      setOriginalData(original);

      setInitNewRow((state) => ({
        ...state,
        DimList: {
          Dim: Data,
        },
      }));
    }

    if (mounted) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    fetchDimListByModule(mounted);
    return function cleanup() {
      mounted = false;
    };
  }, []);

  const AddNewRow = () => {
    setInitNewRow((state) => ({
      ...state,
      GroupNo: autoGroupNo,
    }));
    setSummaryValue({
      netAmt: 0,
      taxAmt1: 0,
      taxAmt2: 0,
      total: 0,
      baseNetAmt: 0,
      baseTaxAmt1: 0,
      baseTaxAmt2: 0,
      baseTotal: 0,
    });
    setEditIndex("");
    setShowAdd(true);
  };

  const UpdateRow = (index) => {
    const dataRow = initData.Detail.find((i) => i.index === index);
    const CurrRate = getValues("CurrRate");
    const Qty = ToNumber(dataRow["Qty"]);
    const Price = ToNumber(dataRow["Price"]);
    const TaxRate1 = ToNumber(dataRow["TaxRate1"]);
    const TaxRate2 = ToNumber(dataRow["TaxRate2"]);
    let NetAmt = Qty * Price;
    let Total = 0;
    //check TaxType
    let TaxAmt1 = FindTaxAmount(dataRow["TaxType1"], TaxRate1, NetAmt);
    let TaxAmt2 = FindTaxAmount(dataRow["TaxType2"], TaxRate2, NetAmt);
    //check overwrite
    if (dataRow["TaxOverwrite1"]) {
      TaxAmt1 = ToNumber(dataRow["TaxAmt1"]);
    }
    if (dataRow["TaxOverwrite2"]) {
      TaxAmt2 = ToNumber(dataRow["TaxAmt2"]);
    }
    if (dataRow["TaxType1"] === "Include") {
      NetAmt = ToNumber(NetAmt - TaxAmt1);
    }
    Total = ToNumber(NetAmt) + ToNumber(TaxAmt1);

    setSummaryValue((state) => ({
      ...state,
      netAmt: ToNumber(NetAmt),
      taxAmt1: ToNumber(TaxAmt1),
      taxAmt2: ToNumber(TaxAmt2),
      total: ToNumber(Total),
      baseNetAmt: ToNumber(NetAmt * CurrRate),
      baseTaxAmt1: ToNumber(TaxAmt1 * CurrRate),
      baseTaxAmt2: ToNumber(TaxAmt2 * CurrRate),
      baseTotal: ToNumber(Total * CurrRate),
    }));

    setValue("NetAmt", NetAmt);
    setValue("TotalAmt", Total);
    setEditIndex(index);
    setShowAdd(true);
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        customHeadRender: ({ label }) => {
          return (
            <TableCell align="center" className={classes.colorCell}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (value, tableMeta) => {
          if (initData.Detail[tableMeta.rowIndex]?.PfmSeq) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {SettingAr.AllowEditFolioInInvoice && (
                  <EditIcon
                    fontSize="small"
                    color="primary"
                    style={{ cursor: "pointer", marginBottom: 6 }}
                    onClick={() => UpdateRow(value)}
                  />
                )}
                <Chip label={"Folio"} />
              </div>
            );
          } else {
            return (
              <>
                <EditIcon
                  fontSize="small"
                  color="primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => UpdateRow(value)}
                />
              </>
            );
          }
        },
      },
    },
    {
      name: "DeptCode",
      label: "Dept.",
      options: {
        sort: true,
        customHeadRender: ({ label }) => {
          return (
            <TableCell className={classes.colorCell} style={{ width: 80 }}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (val, tableMeta) => {
          let deptDesc = tableMeta.rowData[2];
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{deptDesc}</p>} arrow placement="top">
              <div>
                <div className={classes.cellStyleEllipsis}>{val}</div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "DeptDesc",
      label: "Department Name",
      options: {
        display: false,
      },
    },
    {
      name: "CrAcc",
      label: "Account #",
      options: {
        sort: true,
        customHeadRender: ({ label }) => {
          return (
            <TableCell className={classes.colorCell} style={{ width: 100 }}>
              {label}
            </TableCell>
          );
        },
        customBodyRender: (val, tableMeta) => {
          let accDesc = tableMeta.rowData[4];
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{accDesc}</p>} arrow placement="top">
              <div>
                <div className={classes.cellStyleEllipsis}>{val}</div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "CrAccDesc",
      label: "Account Name",
      options: {
        display: false,
      },
    },
    {
      name: "InvdDesc",
      label: "Comment",
      options: {
        display: true,
        customBodyRender: (val) => {
          return (
            <Tooltip title={<p style={{ fontSize: 14 }}>{val}</p>} arrow placement="top">
              <div className={classes.divComment}>
                <div className={classes.parentStyle}>
                  <div className={classes.cellStyleEllipsis}>{val}</div>
                </div>
              </div>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "Qty",
      label: "Qty",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "qty");
        },
      },
    },
    {
      name: "Price",
      label: "Price/Unit",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value, "unit");
        },
      },
    },
    {
      name: "NetAmt",
      label: "Net Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "TaxAmt1",
      label: "TAX",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value, tableMeta) => {
          return NumberFormat(ToNumber(value) + ToNumber(tableMeta.rowData[10]));
        },
      },
    },
    {
      name: "TaxAmt2",
      label: "Tax 2",
      options: {
        display: false,
      },
    },
    {
      name: "TotalAmt",
      label: "Total",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "Unpaid",
      label: "Unpaid",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "DimList",
      label: "DimList",
      options: {
        display: false,
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    responsive: "standard",
    selectableRows: "multiple",
    fixedHeader: true,
    tableBodyHeight: "500px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      const sumNet = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumTax1 = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[9]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumTotal = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[11]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumUnpaid = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[12]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            <TableCell className={footerClasses} />
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                switch (col.name) {
                  case "NetAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumNet}
                      </TableCell>
                    );
                  case "TaxAmt1":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTax1}
                      </TableCell>
                    );
                  case "TotalAmt":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumTotal}
                      </TableCell>
                    );
                  case "Unpaid":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumUnpaid}
                      </TableCell>
                    );
                  default:
                    return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.index);
      for (let i = removeArray.length - 1; i >= 0; i--) initData.Detail.splice(removeArray[i], 1);
    },
  };

  const CheckSelectedArNo = () => {
    const arNo = getValues("ArNo");
    const CurrRate = getValues("CurrRate");
    if (arNo && arNo !== "") {
      setSummaryValue((state) => ({
        ...state,
        netAmt: ToNumber(state.netAmt),
        taxAmt1: ToNumber(state.taxAmt1),
        taxAmt2: ToNumber(state.taxAmt2),
        total: ToNumber(state.netAmt + state.taxAmt1 + state.taxAmt2),
        baseNetAmt: ToNumber(state.netAmt * CurrRate),
        baseTaxAmt1: ToNumber(state.taxAmt1 * CurrRate),
        baseTaxAmt2: ToNumber(state.taxAmt2 * CurrRate),
        baseTotal: ToNumber((state.netAmt + state.taxAmt1 + state.taxAmt2) * CurrRate),
      }));
      setInitNewRow(
        (state) => ({
          ...state,
          DeptCode: SettingAr.InvoiceDeptCode,
          DeptDesc: SettingAr.InvoiceDeptDesc,
          DrAcc: SettingAr.InvoiceDrAcc,
          DrAccDesc: SettingAr.InvoiceDrAccDesc,
          CrAcc: SettingAr.InvoiceCrAcc,
          CrAccDesc: SettingAr.InvoiceCrAccDesc,
          TaxType1: SettingAr.TaxType1,
          TaxAcc1: SettingAr.TaxAcc1,
          TaxAcc1Desc: SettingAr.TaxAcc1Desc,
          TaxRate1: SettingAr.TaxRate1,
          TaxType2: SettingAr.TaxType2,
          TaxAcc2: SettingAr.TaxAcc2,
          TaxAcc2Desc: SettingAr.TaxAcc2Desc,
          TaxRate2: SettingAr.TaxRate2,
        }),
        () => AddNewRow()
      );
    } else {
      SnackbarUtils.warning(translate("ra.permission.selectProfileFirst"));
    }
  };

  const CheckBeforeSelectFolio = () => {
    const arNo = getValues("ArNo");
    if (arNo && arNo !== "") {
      setOpenDialogFolio(true);
      setAutoGroupNo(autoGroupNo + 1);
    } else {
      SnackbarUtils.warning(translate("ra.permission.selectProfileFirst"));
    }
  };

  const CustomHeader = (props) => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={1}>
              <IconButton size={"small"} onClick={CheckSelectedArNo} style={{ marginLeft: 6 }}>
                <AddIcon />
              </IconButton>
            </TableCell>
            <TableCell colSpan={4} style={{ paddingLeft: 0 }}>
              <Button variant="outlined" onClick={CheckBeforeSelectFolio}>
                Select from folio
              </Button>
            </TableCell>
            <TableCell align="right" colSpan={10} />
          </TableRow>
        </TableHead>
        <MuiTableHead {...props} />
      </>
    );
  };

  const SaveFromPopup = (arr, row) => {
    const index = arr.Detail.findIndex((el) => el.index === editIndex);
    row.NetAmt = NumberFormat(summaryValue.netAmt);
    row.NetBaseAmt = NumberFormat(summaryValue.baseNetAmt);
    row.TaxAmt1 = NumberFormat(summaryValue.taxAmt1);
    row.TaxBaseAmt1 = NumberFormat(summaryValue.baseTaxAmt1);
    row.TaxAmt2 = NumberFormat(summaryValue.baseTaxAmt2);
    row.TaxBaseAmt2 = NumberFormat(summaryValue.baseTaxAmt2);
    row.TotalAmt = NumberFormat(summaryValue.total);
    row.TotalBaseAmt = NumberFormat(summaryValue.baseTotal);
    row.Unpaid = NumberFormat(summaryValue.total);

    if (editIndex !== "") {
      //update
      arr.Detail[index] = row;
      setInitData(arr);
      setShowAdd(false);
    } else {
      //create
      if (arr.Detail) {
        row.index = arr.Detail.length;
        arr.Detail = [...arr.Detail, row];
        setInitData(arr);
        setShowAdd(false);
      }
    }
    setAutoGroupNo(autoGroupNo + 1);
    //reset dimension value
    fetchDimListByModule(true);
  };

  const CancelFromPopup = () => {
    setSummaryValue({
      netAmt: ToNumber(0),
      taxAmt1: ToNumber(0),
      taxAmt2: ToNumber(0),
      total: ToNumber(0),
      baseNetAmt: ToNumber(0),
      baseTaxAmt1: ToNumber(0),
      baseTaxAmt2: ToNumber(0),
      baseTotal: ToNumber(0),
    });
    setShowAdd(false);
    if (autoGroupNo > initData.Detail.length + 1) {
      setAutoGroupNo(autoGroupNo - 1);
    }
  };

  const CheckTaxType = (m, data) => {
    if (data["TaxType1"] !== "None" && !!data["TaxAcc1"] === false) {
      m.setError("TaxAcc1", {
        type: "required",
        message: "* Required",
      });
    }
    if (data["InvdTaxT2"] !== "None" && !!data["TaxAcc2"] === false) {
      m.setError("TaxAcc2", {
        type: "required",
        message: "* Required",
      });
    }
    if (data["TaxType1"] !== "None" && ToNumber(data["TaxRate1"]) === 0) {
      m.setError("TaxRate1", {
        type: "required",
        message: "* Required",
      });
    } else {
      m.clearErrors("TaxRate1");
    }
    if (data["TaxType2"] !== "None" && ToNumber(data["TaxRate2"]) === 0) {
      m.setError("TaxRate2", {
        type: "required",
        message: "* Required",
      });
    } else {
      m.clearErrors("TaxRate2");
    }
    if (data["TaxType1"] === "None") {
      m.clearErrors("TaxAcc1");
      m.clearErrors("TaxRate1");
      m.setValue("TaxAcc1", "");
      m.setValue("TaxRate1", 0);
    }
    if (data["TaxType2"] === "None") {
      m.clearErrors("TaxAcc2");
      m.clearErrors("TaxRate2");
      m.setValue("TaxAcc2", "");
      m.setValue("TaxRate2", 0);
    }
    if (data["TaxAcc1"]) {
      m.clearErrors("TaxAcc1");
    }
    if (data["TaxAcc2"]) {
      m.clearErrors("TaxAcc2");
    }
  };

  const UpdateFromPopup = (currentField, m, detail) => {
    //Verify & Set Detail to FormValue
    const CurrRate = getValues("CurrRate");
    const Qty = ToNumber(detail["Qty"]);
    const Price = ToNumber(detail["Price"]);
    const TaxRate1 = ToNumber(detail.TaxType1 !== "None" ? detail["TaxRate1"] : 0);
    const TaxRate2 = ToNumber(detail.TaxType2 !== "None" ? detail["TaxRate2"] : 0);
    let NetAmt = Qty * Price;
    let TaxAmt1 = FindTaxAmount(detail["TaxType1"], TaxRate1, NetAmt);
    let TaxAmt2 = FindTaxAmount(detail["TaxType2"], TaxRate2, NetAmt);

    CheckTaxType(m, detail);

    //TaxOverwrite1
    if (detail["TaxOverwrite1"] || detail["TaxOverwrite1"] === "O") {
      m.setValue("TaxAmt1", detail["TaxAmt1"]);
      TaxAmt1 = ToNumber(detail["TaxAmt1"]);
    } else {
      m.setValue("TaxAmt1", TaxAmt1);
    }
    //TaxOverwrite2
    if (detail.TaxOverwrite2 || detail.TaxOverwrite2 === "O") {
      m.setValue("TaxAmt2", detail["TaxAmt2"]);
      TaxAmt2 = ToNumber(detail["TaxAmt2"]);
    } else {
      m.setValue("TaxAmt2", TaxAmt2);
    }

    if (detail["TaxType1"] === "Include") {
      NetAmt = NetAmt - TaxAmt1;
    }
    if (detail["TaxType2"] === "Include") {
      NetAmt = NetAmt - TaxAmt2;
    }

    const Total = NetAmt + TaxAmt1 + TaxAmt2;
    setSummaryValue((state) => ({
      ...state,
      netAmt: ToNumber(NetAmt),
      taxAmt1: ToNumber(TaxAmt1),
      taxAmt2: ToNumber(TaxAmt2),
      total: ToNumber(Total),
      baseNetAmt: ToNumber(NetAmt * CurrRate),
      baseTaxAmt1: ToNumber(TaxAmt1 * CurrRate),
      baseTaxAmt2: ToNumber(TaxAmt2 * CurrRate),
      baseTotal: ToNumber(Total * CurrRate),
    }));
    m.setValue("NetAmt", NetAmt);
    m.setValue("TotalAmt", Total);
  };

  const UpdateForm = (e) => {
    const values = getValues();
    //set some input readonly
    if (e.target.name === "RunNoType") {
      if (e.target.value === "true") {
        document.getElementsByName("InvNo")[0].parentNode.parentNode.firstChild.classList.remove("Mui-disabled");
        document.getElementsByName("InvNo")[0].parentNode.classList.remove("Mui-disabled");
        document.getElementsByName("InvNo")[0].disabled = false;
        document.getElementsByName("InvNo")[0].focus();
        setValue("InvNo", "");
        // setValue("TaxRate", 0);
        // setValue("TaxAmt", 0);
        // setValue("TaxBaseAmt", 0);
      } else {
        document.getElementsByName("InvNo")[0].parentNode.parentNode.firstChild.classList.add("Mui-disabled");
        document.getElementsByName("InvNo")[0].parentNode.classList.add("Mui-disabled");
        document.getElementsByName("InvNo")[0].disabled = true;
        setValue("InvNo", "Auto");
      }
    }

    if (e.target.name === "CurrRate") {
      const rate = ToNumber(e.target.value);
      initData.Detail.forEach((item) => {
        item.NetBaseAmt = item.NetAmt * ToNumber(rate);
        item.TaxBaseAmt1 = item.TaxAmt1 * ToNumber(rate);
        item.TaxBaseAmt2 = item.TaxAmt2 * ToNumber(rate);
        item.TotalBaseAmt = item.TotalAmt * ToNumber(rate);
      });
    }
    if (ToNumber(values.CreditTerm) !== 0 && e.target.name !== "InvhDate") {
      const invDate = new Date(values.InvhDate);
      const newDueDate = addDays(invDate, parseInt(values.CreditTerm));
      setValue("InvhDueDate", newDueDate);
    }
    if (e.target.name === "InvhDate") {
      const newDate = new Date(e.target.value);
      const creditTerm = parseInt(values.CreditTerm);
      const newDueDate = addDays(newDate, parseInt(creditTerm));
      setValue("InvhDate", newDate);
      setValue("InvhDueDate", newDueDate);
    }

    if (e.target.name === "TaxType") {
      const taxType = e.target.value;
      if (taxType !== "None") {
        setValue("InvhTaxNo", values.InvNo);
      } else {
        setValue("InvhTaxNo", "");
      }
    }
  };

  const Save = async (values) => {
    //Validate & CheckDetail
    if (values.Detail.length > 0) {
      values.Detail.forEach((item) => {
        if (item["TaxOverwrite1"]) {
          item["TaxOverwrite1"] = "O";
        } else {
          item["TaxOverwrite1"] = "";
        }
        if (item["TaxOverwrite2"]) {
          item["TaxOverwrite2"] = "O";
        } else {
          item["TaxOverwrite2"] = "";
        }
      });
      const { Code, InternalMessage, UserMessage } = await createArInvoiceDetail(values);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect(`${InternalMessage}/show`);
        });
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
    } else {
      SnackbarUtils.info(translate("ra.info.notransaction"));
      setBtnLoading(false);
      return;
    }
    setLoading(false);
  };

  const CancelFnc = () => {
    redirect("list", basePath);
  };

  if (loading) return <Loading />;
  if (!initData) return null;

  return (
    <div>
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Paper style={{ padding: 16 }}>
          <BoxHeader header={"Invoice"} status={initData.InvhSource ? initData.InvhSource : undefined} />
          <Grid container alignItems="flex-start" spacing={1}>
            {formFields
              ? formFields.map((item, idx) => (
                  <Grid item xs={item.size} key={idx} style={item.style}>
                    {React.createElement(item.field.type, {
                      ...{
                        ...item.field.props,
                        methods,
                        key: item.field.props.name,
                        onChange: UpdateForm,
                      },
                    })}
                  </Grid>
                ))
              : ""}
          </Grid>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <MuiTranslateTable
                data={initData.Detail}
                columns={columns}
                options={options}
                components={{
                  TableHead: CustomHeader,
                }}
              />
            </Grid>
          </Grid>
          {showAdd && (
            <PopupTable
              initialValues={editIndex !== "" ? initData.Detail.find((i) => i.index === editIndex) : initNewRow}
              formFields={formFieldsDetail}
              formFieldsTax1={formFieldsTax1}
              formFieldsTax2={formFieldsTax2}
              update={UpdateFromPopup}
              checkTaxType={CheckTaxType}
              open={showAdd}
              save={(row) => SaveFromPopup(initData, row)}
              cancel={CancelFromPopup}
              //maxWidth={"md"}
              showDim
            >
              <Box style={{ margin: 16 }}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={6} style={{ display: "none" }}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="h6" gutterBottom>
                          {translate("ra.field.Summary")}
                        </Typography>
                      </Box>
                    </Box>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Net Amount")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.netAmt)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Tax 1")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.taxAmt1)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Tax 2")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.taxAmt2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Total")}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.total)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex">
                      <Box flexGrow={1}>
                        <Typography variant="h6" gutterBottom>
                          {translate("ra.field.Base Summary")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Rate")} : {NumberFormat(getValues("CurrRate"), "currency")}
                        </Typography>
                      </Box>
                    </Box>
                    <Grid container spacing={1} justifyContent="center" alignItems="center">
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Net Amount")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.baseNetAmt)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Tax 1")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.baseTaxAmt1)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Tax 2")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.baseTaxAmt2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                          {translate("ra.field.Total")}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1" align="right" gutterBottom>
                          {NumberFormat(summaryValue.baseTotal)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </PopupTable>
          )}
          {openDialogFolio && (
            <DialogFolio
              open={openDialogFolio}
              onClose={(arr) => {
                if (arr && arr.length > 0) {
                  if (initData.Detail.length > 0) {
                    arr.forEach((element) => {
                      const idx = initData.Detail.findIndex((i) => i.PfmSeq === element.PfmSeq);
                      if (idx !== -1) {
                        initData.Detail.splice(idx, 1);
                      }
                    });
                  }
                  const oldData = initData.Detail.filter((i) => i.PfmSeq === null || i.PfmSeq === 0);
                  initData.Detail = [...oldData, ...arr];
                  setInitData(initData);
                } else if (arr && arr.length === 0) {
                  initData.Detail = initData.Detail.filter((item) => item.Selected !== true);
                  setInitData(initData);
                  setAutoGroupNo(autoGroupNo - 1);
                }
                setOpenDialogFolio(false);
              }}
              ArNo={getValues("ArNo")}
              Rate={getValues("CurrRate")}
              currentDetail={initData.Detail}
              initNewRow={initNewRow}
              rowLength={initData.Detail.length + 1}
              lookupList={lookupList}
            />
          )}
        </Paper>
        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(initData, 0, 2) : ""}</pre>
        <ButtonFooter disabled={isBtnLoading} CancelFnc={CancelFnc} />
      </form>
    </div>
  );
};

export default Create;
