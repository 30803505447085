/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useCallback } from "react";
import { GblContext } from "providers/formatter";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import clsx from "clsx";
import { Loading, useRedirect, useTranslate } from "react-admin";
import { Paper, Grid, Button, TextField, Typography, Checkbox } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { TableHead, TableFooter, TableRow, TableCell, FormControlLabel } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import { TableHead as MuiTableHead } from "mui-datatables";
import MuiTranslateTable from "components/MuiTranslateTable";
import ActionMenu from "components/ActionMenu";
import BoxHeader from "components/BoxHeader";
import ButtonFooter from "components/ButtonFooter";
import NavRight from "components/NavRightSide";
import PopupSettlementAr from "components/PopupSettlementAr";
import NumberFormatInput from "components/NumberFormatInput";
import { getArReceiptDetail, updateArReceiptDetail } from "services/accountReceivable";
import SnackbarUtils from "utils/SnackbarUtils";
import { GetWfStatus } from "utils/options";

const Edit = (props) => {
  const translate = useTranslate();
  const classes = props.useStyles();
  const {
    basePath,
    id,
    formFields,
    formFieldsReceiptCrAccount,
    formFieldsReceiptDrAccount,
    formFieldsGainLossAccount,
    formFieldsBank,
    formFieldsBilling,
    formFieldsWht,
    formFieldsWht2,
    arProfileList,
    handleFocus,
    wfEnumStatus,
    wfSteps,
  } = props;
  const redirect = useRedirect();
  const { settingAll, DateToString, NumberFormat, ToNumber, FindTaxAmount } = useContext(GblContext);
  const { SettingSystem, SettingAr } = settingAll;
  const [data, setData] = useStateWithCallbackLazy();
  const [unselectInvoice, setUnselectInvoice] = useState([]);
  const [sumInvAmt, setSumInvAmt] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showWhtOw, setShowWhtOw] = useState(false);
  const [checkSettleDate, setCheckSettleDate] = useState(false);

  const methods = useForm({ defaultValues: data });

  const { handleSubmit, getValues, setValue, reset } = methods;

  const fetchReceiptById = useCallback(async () => {
    setLoading(true);
    const response = await getArReceiptDetail(id);
    if (response) {
      response.WhtDept2 = response.WhtDept;
      response.WhtAcc2 = response.WhtAcc;
      response.Detail.forEach((element) => {
        element.InvhDate = element.Info.InvoiceHeader.InvhDate;
        element.CurrCode = element.Info.InvoiceHeader.CurrCode;
        element.CurrRate = element.Info.InvoiceHeader.CurrRate;
        element.InvdDesc = element.Info.InvoiceDetail.InvdDesc;
        element.TotalAmt = element.InvAmount;
        element.RcptPaid = element.RcptdAmount;
        element.RcptPaidBaseAmount = element.RcptdBaseAmount;
      });
      response.DepositAmount = response.Detail.length > 0 ? 0 : response.Amount;
      setCheckSettleDate(response.SetAsSettleDate);
      setData(response);
      reset(response);
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [id, reset]);

  useEffect(() => {
    fetchReceiptById();
  }, [fetchReceiptById]);

  const disableFormEnter = (e) => {
    if (e.key === "Enter" && e.target.localName !== "textarea") e.preventDefault();
  };

  const onSubmit = async () => {
    const values = getValues();
    const isCheckGLAccountPass = CheckGLAccount();
    const isCheckBankAccountPass = CheckBankAccount();
    const isCheckWhtAccountPass = CheckWhtAccount();

    if (!CheckAfterSubmit(values)) {
      return false;
    }

    if (checkSettleDate) {
      data.Detail.forEach((element) => {
        element.InvhDate = element.RcptdDate;
      });
    }

    if (
      isCheckGLAccountPass &&
      isCheckBankAccountPass &&
      isCheckWhtAccountPass &&
      Object.keys(methods.errors).length === 0
    ) {
      const values = getValues();
      setLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 500));
      setData(
        (state) => ({
          ...state,
          ...values,
          SetAsSettleDate: checkSettleDate,
          CurrRate: ToNumber(values.CurrRate),
          Amount: ToNumber(state.Amount),
          BaseAmt: ToNumber(state.BaseAmt),
          NetBaseAmt: ToNumber(state.NetBaseAmt),
          GainLossAmt: ToNumber(state.GainLossAmt),
          WhtAmt: ToNumber(state.WhtAmt),
          BankChargeAmt: ToNumber(state.BankChargeAmt),
          TaxRate: ToNumber(values.TaxRate),
          TaxAmt: ToNumber(values.TaxAmt),
          TaxBaseAmt: ToNumber(values.TaxAmt) * ToNumber(state.CurrRate),
        }),
        (nextState) => Save(nextState)
      );
    } else {
      return false;
    }
  };

  const menuControlProp = [
    { name: "Back", fnc: () => redirect("list", basePath) },
    { name: "Add", disabled: true },
    { name: "Edit", disabled: true },
    { name: "Void", disabled: true },
    { name: "Print", disabled: true },
  ];

  const [showAdd, setShowAdd] = useState(false);
  const [openDim, setOpenDim] = useState(false);

  const AddNewRow = () => {
    setShowAdd(true);
  };

  const SaveFromPopup = (arr, rows) => {
    //clear OldDetail
    arr.Detail = [];
    const arrayOfNewRows = [];
    //adjustRows
    rows.forEach((item) => {
      arrayOfNewRows.push({
        ...item,
        InvAmount: item.InvAmount ? item.InvAmount : item.TotalAmt,
        RcptdDate: item.RcptdDate,
        RcptdCurrCode: item.RcptdCurrCode ? item.RcptdCurrCode : item.CurrCode,
        RcptdCurrRate: item.RcptdCurrRate ? item.RcptdCurrRate : ToNumber(item.CurrRate),
        RcptdAmount: item.RcptPaid,
        RcptdBaseAmount: item.RcptPaid * ToNumber(item.CurrRate),
        RcptPaid: item.RcptPaid,
        RcptPaidBaseAmount: item.RcptPaid * ToNumber(item.CurrRate),
      });
    });
    //selected
    const selected = arrayOfNewRows.filter((item) => item.IsPaid);
    //unselect
    const unselect = arrayOfNewRows.filter((item) => !item.IsPaid);
    arr.Detail = [...selected];

    setUnselectInvoice(unselect);
    // if (selected.length > 0) {
    //   CalculateTaxInvoice(selected);
    // }
    CalculatePayment(arr);
    setShowAdd(false);
  };

  const CancelFromPopup = (resetData) => {
    setData((state) => ({
      ...state,
      Detail: resetData,
    }));
    setShowAdd(false);
  };

  // const CalculateTaxInvoice = (rows) => {
  //   const sumTaxRate1 = rows.reduce((accu, item) => {
  //     const s = ToNumber(accu) + ToNumber(item.TaxRate1);
  //     return ToNumber(s ?? 0);
  //   }, 0);
  //   const sumTaxAmt1 = rows.reduce((accu, item) => {
  //     const s = ToNumber(accu) + ToNumber(item.TaxAmt1);
  //     return ToNumber(s ?? 0);
  //   }, 0);
  //   const sumTaxBaseAmt1 = rows.reduce((accu, item) => {
  //     const s = ToNumber(accu) + ToNumber(item.TaxBaseAmt1);
  //     return ToNumber(s ?? 0);
  //   }, 0);

  //   setValue("TaxRate", sumTaxRate1);
  //   setValue("TaxAmt", sumTaxAmt1);
  //   setValue("TaxBaseAmt", sumTaxBaseAmt1);
  //   setValue("RunTaxType", sumTaxBaseAmt1 > 0 ? true : false);
  //   setValue("TaxInvNo", sumTaxBaseAmt1 > 0 ? "Auto" : "");
  // };

  const CalculateTaxPayment = (paymentAmount) => {
    if (!methods.watch("TaxOverwrite")) {
      const TaxRate = methods.watch("TaxRate");
      const r = FindTaxAmount(SettingAr.ReceiptTaxType, ToNumber(TaxRate), ToNumber(paymentAmount));
      setValue("TaxAmt", r);
    }
  };

  const CalculateWhtAmt = (t, r) => {
    // const whtTotal = ToNumber(t);
    // const whtRate = ToNumber(r);
    const whtAmt = FindTaxAmount("Add", r, t);
    setData((state) => ({
      ...state,
      AmtBfWht: t,
      WhtRate: r,
      WhtAmt: whtAmt,
    }));

    if (whtAmt !== 0) {
      CheckWhtAccount();
    }
  };

  const CheckBaseCurrency = (numGainLoss, numInvAmt, newPayAmt, curCode) => {
    if (SettingSystem.DefaultCurrencyCode === curCode) {
      return 0;
    } else {
      return NumberFormat(numGainLoss !== undefined ? numGainLoss : numInvAmt - newPayAmt);
    }
  };

  const CalculatePayment = (data) => {
    if (data) {
      const numCurRate = ToNumber(methods.watch("CurrRate"));
      const sumPaidAmt = data.Detail.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.RcptPaid);
        return s ?? 0;
      }, 0);
      const sumPaidBAmt = data.Detail.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.RcptPaidBaseAmount);
        return NumberFormat(s ?? 0);
      }, 0);
      const numInvAmt = ToNumber(sumPaidBAmt);
      const numWhtAmt = ToNumber(data.WhtAmt ?? 0);
      const numBankChargeAmt = ToNumber(data.BankChargeAmt ?? 0);

      const newSettlePay = data.Amount * numCurRate;
      data.BaseAmt = numInvAmt;

      if (data.DepositAmount > 0) {
        data.Amount = data.DepositAmount;
        data.BaseAmt = data.DepositAmount * data.CurrRate;
      } else {
        data.Amount = sumPaidAmt;
        data.BaseAmt = sumPaidBAmt;
      }

      data.GainLossAmt = CheckBaseCurrency(data.GainLossAmt, numInvAmt, newSettlePay);
      // data.GainLossAmt =
      // 	data.GainLossAmt !== 0 ? data.GainLossAmt : numInvAmt - newSettlePay;
      data.AvlCrAmt = newSettlePay - numInvAmt;
      data.AvlCrBaseAmt = newSettlePay - numInvAmt * numCurRate;
      data.NetBaseAmt = NumberFormat(newSettlePay - data.GainLossAmt - numWhtAmt - numBankChargeAmt);
      setSumInvAmt(sumPaidBAmt);
      setData(data);
    }
  };

  const AdjustPaymentAmount = (numInvAmt, numCurAmt, numWhtAmt, numBankChargeAmt, numGainLoss) => {
    const curCode = methods.watch("CurrCode");
    const numCurRate = ToNumber(methods.watch("CurrRate"));
    const newCurAmt = ToNumber(numCurAmt);
    const newPayAmt = ToNumber(newCurAmt * numCurRate);

    const newGainloss = CheckBaseCurrency(numGainLoss, numInvAmt, newPayAmt, curCode);
    let newAvlCrAmt = 0;
    let newAvlCrBaseAmt = 0;
    if (newPayAmt > numInvAmt && newGainloss === 0) {
      //newAvlCrBaseAmt = newPayAmt - numInvAmt + newGainloss;
      newAvlCrAmt = NumberFormat(newPayAmt - numInvAmt);
      newAvlCrBaseAmt = NumberFormat(newPayAmt - numInvAmt * numCurRate);
    }
    //if (data.Detail.length === 0) {
    CalculateTaxPayment(newPayAmt);
    //}

    setData((state) => ({
      ...state,
      BankChargeAmt: numBankChargeAmt,
      CurrRate: numCurRate,
      Amount: numCurAmt,
      BaseAmt: numCurAmt * numCurRate,
      GainLossAmt: newGainloss,
      WhtAmt: NumberFormat(numWhtAmt),
      NetAmt: NumberFormat((newPayAmt - numWhtAmt) / numCurRate),
      NetBaseAmt: NumberFormat(newPayAmt - numWhtAmt - numBankChargeAmt),
      // NetAmt: (newPayAmt + newGainloss - numWhtAmt) / numCurRate,
      // NetBaseAmt: newPayAmt + newGainloss - numWhtAmt - numBankChargeAmt,
      AvlCrAmt: newAvlCrAmt,
      AvlCrBaseAmt: newAvlCrBaseAmt,
    }));
    if (newGainloss !== 0) {
      CheckGLAccount(newGainloss);
    } else {
      methods.clearErrors("DeptGL");
      methods.clearErrors("GainLossAcc");
    }
  };

  const SetDepositAmount = (numCurAmt, detailLength) => {
    if (detailLength === 0) {
      setData((state) => ({
        ...state,
        DepositAmount: numCurAmt,
      }));
    }
  };

  const UpdateForm = (e) => {
    const arNo = getValues("ArNo");
    const billTo = getValues("BillTo");
    //set some input readonly
    if (e.target.name === "RunNoType") {
      if (e.target.value === "true") {
        document.getElementsByName("RcptRefNo")[0].parentNode.parentNode.firstChild.classList.remove("Mui-disabled");
        document.getElementsByName("RcptRefNo")[0].parentNode.classList.remove("Mui-disabled");
        document.getElementsByName("RcptRefNo")[0].disabled = false;
        document.getElementsByName("RcptRefNo")[0].focus();
        setValue("RcptRefNo", "");
        // setValue("TaxRate", 0);
        // setValue("TaxAmt", 0);
        // setValue("TaxBaseAmt", 0);
      } else {
        document.getElementsByName("RcptRefNo")[0].parentNode.parentNode.firstChild.classList.add("Mui-disabled");
        document.getElementsByName("RcptRefNo")[0].parentNode.classList.add("Mui-disabled");
        document.getElementsByName("RcptRefNo")[0].disabled = true;
        setValue("RcptRefNo", "Auto");
      }
    }

    if (e.target.name === "RunTaxType") {
      if (e.target.value === "false") {
        // setValue("TaxInvNo", "");
        // setValue("TaxRate", 0);
        // setValue("TaxAmt", 0);
        // setValue("TaxBaseAmt", 0);
        document.getElementsByName("TaxInvNo")[0].parentNode.parentNode.firstChild.classList.add("Mui-disabled");
        document.getElementsByName("TaxInvNo")[0].parentNode.classList.add("Mui-disabled");
        document.getElementsByName("TaxInvNo")[0].disabled = true;
        setValue("TaxInvNo", data.RcptRefNo);
      } else {
        document.getElementsByName("TaxInvNo")[0].parentNode.parentNode.firstChild.classList.remove("Mui-disabled");
        document.getElementsByName("TaxInvNo")[0].parentNode.classList.remove("Mui-disabled");
        document.getElementsByName("TaxInvNo")[0].disabled = false;
        document.getElementsByName("TaxInvNo")[0].focus();
        setValue("TaxInvNo", "");
        setValue("TaxRate", 0);
        setValue("TaxAmt", 0);
      }
    }

    if (e.target.name === "TaxOverwrite") {
      if (e.target.value === "false") {
        document.getElementsByName("TaxAmt")[0].parentNode.parentNode.firstChild.classList.remove("Mui-disabled");
        document.getElementsByName("TaxAmt")[0].parentNode.classList.remove("Mui-disabled");
        document.getElementsByName("TaxAmt")[0].disabled = false;
        document.getElementsByName("TaxAmt")[0].focus();
      } else {
        document.getElementsByName("TaxAmt")[0].parentNode.parentNode.firstChild.classList.add("Mui-disabled");
        document.getElementsByName("TaxAmt")[0].parentNode.classList.add("Mui-disabled");
        document.getElementsByName("TaxAmt")[0].disabled = true;
        CalculateTaxPayment(data.Amount);
        // const numCurRate = ToNumber(methods.watch("CurrRate"));
        // const taxAmt = FindTaxAmount("Include", ToNumber(data.TaxRate), ToNumber(data.Amount));
        // setValue("TaxAmt", taxAmt);
        // setValue("TaxBaseAmt", taxAmt * numCurRate);
      }
    }

    if (arNo && arNo !== data.ArNo) {
      const arItem = arProfileList.find((item) => item.ArNo == arNo);
      const b = arItem.Billto ? arItem.Billto : arItem.BillTo;
      const address = arItem.AddressInfo[`${b}`];
      setValue("ArNo", arItem.ArNo);
      setValue(
        "BillToName",
        `${arItem.Title ? `${arItem.Title} ` : " "}${arItem.FirstName ? `${arItem.FirstName} ` : " "}${
          arItem.LastName ? `${arItem.LastName} ` : " "
        }`
      );
      setValue("BillTo", arItem.Billto ?? arItem.BillTo);
      setValue("BillToAddress", address);
      setValue("PayType", arItem.PaymentCode);
      setValue("PayDesc", arItem.PaymentDesc);
      setValue("RunTaxType", false);
      setValue("TaxInvNo", "");
      setValue("TaxRate", 0);
      setValue("TaxAmt", 0);
      setSumInvAmt(0);
      setData((state) => ({
        ...state,
        ArNo: arItem.ArNo,
        Detail: [],
        Amount: 0,
        GainLossAmt: 0,
        NetAmt: 0,
        NetBaseAmt: 0,
        AvlCrAmt: 0,
        AvlCrBaseAmt: 0,
      }));
    }

    if (e.target.name === "RunTaxType") {
      if (e.target.value === "false") {
        // setValue("TaxInvNo", "");
        // setValue("TaxRate", 0);
        // setValue("TaxAmt", 0);
        // setValue("TaxBaseAmt", 0);
        document.getElementsByName("TaxInvNo")[0].disabled = false;
      }
    }

    if (e.target.name === "TaxRate") {
      const numCurRate = ToNumber(methods.watch("CurrRate"));
      const taxAmt = FindTaxAmount("Include", ToNumber(e.target.value), ToNumber(data.Amount));
      setValue("TaxAmt", taxAmt);
      setValue("TaxBaseAmt", taxAmt * numCurRate);
    }

    if (e.target.name === "CurrRate") {
      const numSumInvAmt = ToNumber(sumInvAmt);
      const numWhtAmt = ToNumber(data.WhtAmt ?? 0);
      const numPayAmt = data.Amount;
      const numBankChargeAmt = ToNumber(data.BankChargeAmt ?? 0);
      const numGainLoss = ToNumber(data.GainLossAmt ?? 0);
      AdjustPaymentAmount(numSumInvAmt, numPayAmt, numWhtAmt, numBankChargeAmt, numGainLoss);
    }

    if (billTo) {
      const arItem = arProfileList.find((item) => item.ArNo == arNo);
      const address = arItem?.AddressInfo[`${billTo}`];
      setValue("BillTo", billTo);
      setValue("BillToAddress", address);
      setData((state) => ({
        ...state,
        BillTo: billTo,
        BillToAddress: address,
      }));
    }
    //set some input readonly
    //SetDisableInput(values);
  };

  // const SetDisableInput = (values) => {
  //   if (values.RunNoType) {
  //     document.getElementsByName("RcptRefNo")[0].disabled = true;
  //   }
  //   if (values.RunTaxType) {
  //     setValue("TaxInvNo", data.RcptRefNo);
  //     document.getElementsByName("TaxInvNo")[0].disabled = true;
  //   }

  //   if (values.TaxOverwrite) {
  //     document.getElementsByName("TaxAmt")[0].disabled = false;
  //   } else {
  //     const numCurRate = ToNumber(methods.watch("CurrRate"));
  //     const taxAmt = FindTaxAmount("Include", ToNumber(data.TaxRate), ToNumber(data.Amount));
  //     setValue("TaxAmt", taxAmt);
  //     setValue("TaxBaseAmt", taxAmt * numCurRate);
  //     document.getElementsByName("TaxAmt")[0].disabled = true;
  //   }
  // };

  const CheckGLAccount = (glAmt) => {
    const values = getValues();
    const vGlDept = values.DeptGL ?? "";
    const vGlAcc = values.GainLossAcc ?? "";
    const gainLossAmt = glAmt ? ToNumber(glAmt) : ToNumber(data.GainLossAmt);

    if (gainLossAmt !== 0) {
      if (!!vGlDept === false) {
        methods.setError("DeptGL", {
          type: "required",
          message: "* Required",
        });
      } else if (!!vGlAcc === false) {
        methods.setError("GainLossAcc", {
          type: "required",
          message: "* Required",
        });
      } else {
        return true;
      }
      return false;
    }
    if (!!vGlDept === true) {
      methods.clearErrors("DeptGL");
    }
    if (!!vGlAcc === true) {
      methods.clearErrors("GainLossAcc");
    }
    return true;
  };

  const CheckBankAccount = (bankAmt) => {
    const values = getValues();
    const vBankDept = values.BankChargeDept ?? "";
    const vBankAcc = values.BankChargeAcc ?? "";
    const bankAmount = bankAmt ? ToNumber(bankAmt) : ToNumber(data.BankChargeAmt);

    if (bankAmount !== 0) {
      if (!!vBankDept === false) {
        methods.setError("BankChargeDept", {
          type: "required",
          message: "*Required",
        });
      } else if (!!vBankAcc === false) {
        methods.setError("BankChargeAcc", {
          type: "required",
          message: "*Required",
        });
      } else {
        return true;
      }
      return false;
    }
    if (!!vBankDept === true) {
      methods.clearErrors("BankChargeDept");
    }
    if (!!vBankAcc === true) {
      methods.clearErrors("BankChargeAcc");
    }
    return true;
  };

  const CheckWhtAccount = () => {
    const values = getValues();
    const vWhtDept = values.WhtDept ?? "";
    const vWhtAcc = values.WhtAcc ?? "";
    const whtAmount = ToNumber(data.WhtAmt);

    if (whtAmount !== 0) {
      if (!!vWhtDept === false) {
        methods.setError("WhtDept", {
          type: "required",
          message: "*Required",
        });
      } else if (!!vWhtAcc === false) {
        methods.setError("WhtAcc", {
          type: "required",
          message: "*Required",
        });
      } else {
        return true;
      }
      return false;
    }

    if (!!vWhtDept) {
      methods.clearErrors("WhtDept");
    }
    if (!!vWhtAcc) {
      methods.clearErrors("WhtAcc");
    }
    return true;
  };

  const CheckAfterSubmit = (values) => {
    const whtAmount = values.WhtAmt ? ToNumber(values.WhtAmt) : ToNumber(data.WhtAmt);
    const gainLossAmt = values.GainLossAmt ? ToNumber(values.GainLossAmt) : ToNumber(data.GainLossAmt);
    if (!values.WhtDept && ToNumber(whtAmount) !== 0) {
      methods.setError("WhtDept", {
        type: "required",
        message: "* Required",
      });
      //SnackbarUtils.warning(translate("ra.permission.selectWHTAccount"));
      //return false;
    }
    if (!values.WhtAcc && ToNumber(whtAmount) !== 0) {
      methods.setError("WhtAcc", {
        type: "required",
        message: "* Required",
      });
      SnackbarUtils.warning(translate("ra.permission.selectWHTAccount"));
      return false;
    }
    if (!values.DeptGL && ToNumber(gainLossAmt) !== 0) {
      methods.setError("DeptGL", {
        type: "required",
        message: "* Required",
      });
      SnackbarUtils.warning(translate("ra.permission.selectGainLossAccount"));
      return false;
    }
    if (!values.GainLossAcc && ToNumber(gainLossAmt) !== 0) {
      methods.setError("GainLossAcc", {
        type: "required",
        message: "* Required",
      });
      SnackbarUtils.warning(translate("ra.permission.selectGainLossAccount"));
      return false;
    }

    return true;
  };

  const columns = [
    {
      name: "index",
      label: " ",
      options: {
        display: false,
        filter: false,
        viewColumns: false,
      },
    },
    {
      name: "InvNo",
      label: "Invoice No.",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? value?.InvoiceHeader.InvNo : value;
        },
      },
    },
    {
      name: "RcptdDate",
      label: "Settle on",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return DateToString(value);
        },
      },
    },
    {
      name: "InvdDesc",
      label: "Description",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? value?.InvoiceDetail.InvdDesc : value;
        },
      },
    },
    {
      name: "CurrCode",
      label: "Currency",
      options: {
        sort: false,
        customBodyRender: (value) => {
          return typeof value === "object" ? value?.InvoiceHeader.CurrCode : value;
        },
      },
    },
    {
      name: "CurrRate",
      label: "Rate",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object"
            ? NumberFormat(value?.InvoiceHeader.CurrRate, "currency")
            : NumberFormat(value, "currency");
        },
      },
    },
    {
      name: "TotalAmt",
      label: "Amount",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return typeof value === "object" ? NumberFormat(value?.InvoiceDetail.TotalAmt) : NumberFormat(value);
        },
      },
    },
    {
      name: "RcptPaid",
      label: "Paid",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
    {
      name: "RcptPaidBaseAmount",
      label: "Base Amt.",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({
          align: "right",
        }),
        setCellProps: () => ({
          style: {
            textAlign: "right",
          },
        }),
        customBodyRender: (value) => {
          return NumberFormat(value);
        },
      },
    },
  ];

  const footerClasses = clsx({
    [classes.footerCell]: true,
    [classes.stickyFooterCell]: true,
  });

  const options = {
    tableId: "detail",
    responsive: "standard",
    selectableRows: "none",
    serverSide: true,
    fixedHeader: true,
    tableBodyHeight: "300px",
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    elevation: 0,
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    pagination: false,
    customTableBodyFooterRender: function (opts) {
      const sumPaid = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[7]);
        return NumberFormat(s ?? 0);
      }, 0);

      const sumBasePaid = opts.data.reduce((accu, item) => {
        const s = ToNumber(accu) + ToNumber(item.data[8]);
        return NumberFormat(s ?? 0);
      }, 0);
      return (
        <TableFooter className={footerClasses}>
          <TableRow>
            {opts.columns.map((col, index) => {
              if (col.display === "true") {
                switch (col.label) {
                  case "Paid":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumPaid}
                      </TableCell>
                    );
                  case "Base Amt.":
                    return (
                      <TableCell key={index} className={footerClasses}>
                        {sumBasePaid}
                      </TableCell>
                    );
                  default:
                    return <TableCell key={index} className={footerClasses} />;
                }
              }
              return null;
            })}
          </TableRow>
        </TableFooter>
      );
    },
    onRowsDelete: (rowsDeleted) => {
      const removeArray = rowsDeleted.data.map((i) => i.Id);
      for (let i = removeArray.length - 1; i >= 0; i--) data.PayWht.Items.splice(removeArray[i], 1);
    },
  };

  const CheckSelectedArNo = () => {
    const arNo = getValues("ArNo");
    if (arNo && arNo !== "") {
      AddNewRow();
    } else {
      SnackbarUtils.warning(translate("ra.permission.selectProfileFirst"));
    }
  };

  const CustomHeader = (props) => {
    return (
      <>
        <TableHead>
          <TableRow>
            <TableCell colSpan={9} style={{ paddingLeft: 0 }}>
              <Button variant="outlined" onClick={CheckSelectedArNo} startIcon={<AddIcon />}>
                Select Invoice For Settlement
              </Button>
              <FormControlLabel
                style={{ marginLeft: 10 }}
                control={
                  <Checkbox
                    checked={checkSettleDate}
                    color="primary"
                    onChange={(e, newValue) => {
                      if (newValue && data.Detail.length > 0) {
                        const msg = `All Settle date will be update to ${DateToString(
                          data.RcpthDate
                        )}. Do you want to continue?`;
                        SnackbarUtils.confirm(
                          msg,
                          function () {
                            data.Detail.forEach((element) => {
                              element.RcptdDate = data.RcpthDate;
                            });
                            setCheckSettleDate(true);
                          },
                          function () {
                            setCheckSettleDate(false);
                          }
                        );
                      } else {
                        setCheckSettleDate(newValue);
                      }
                    }}
                  />
                }
                label="Set as settle date"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <MuiTableHead {...props} />
      </>
    );
  };

  const CheckValueBeforePost = (values) => {
    const paymentAmount = values.Amount;
    if (paymentAmount === 0 && values.Detail.length === 0) {
      setLoading(false);
      SnackbarUtils.warning(translate("ra.ar.amountWarnningZero"));
      return false;
    }
    // const basePayment = values.Amount * values.CurrRate;
    // if (basePayment < values.BaseAmt) {
    //   setLoading(false);
    //   SnackbarUtils.warning(translate("ra.ar.receiptAmountWarnning1"));
    //   return false;
    // }
    return true;
  };

  const Save = async (values) => {
    const isPass = CheckValueBeforePost(values);
    if (isPass) {
      const { Code, InternalMessage, UserMessage } = await updateArReceiptDetail(values);
      if (Code === 0) {
        SnackbarUtils.success(UserMessage, function () {
          redirect("show", basePath, id, values);
        });
      } else {
        if (InternalMessage) {
          SnackbarUtils.error(InternalMessage);
        } else {
          SnackbarUtils.warning(UserMessage);
        }
      }
      setLoading(false);
    } else {
      setLoading(false);
      return false;
    }
  };

  const ShowDim = (values) => {
    if (!values) {
      setOpenDim(!openDim);
    } else {
      setOpenDim(!openDim);
    }
  };

  const CancelFnc = () => {
    redirect("show", basePath, id);
  };

  if (arProfileList?.length === 0 || loading) return <Loading />;
  if (!data) return null;

  return (
    <div
      // className={clsx({
      //   [classes.drawerOpen]: openDim,
      //   [classes.drawerClose]: !openDim,
      // })}
      className={classes.drawerClose}
    >
      <ActionMenu menuControl={menuControlProp} />

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={disableFormEnter}>
        <Paper className={classes.root}>
          <BoxHeader
            header={"Receipt"}
            status={data.RcpthStatus}
            wfStatus={GetWfStatus(wfSteps, wfEnumStatus, data.RcpthStatus)}
          />
          <Grid container alignItems="flex-start" spacing={1}>
            {formFields
              ? formFields.map((item, idx) => (
                  <Grid item xs={item.size} key={idx} style={item.style}>
                    {React.createElement(item.field.type, {
                      ...{
                        ...item.field.props,
                        methods,
                        key: item.field.props.name,
                        onChange: UpdateForm,
                      },
                    })}
                  </Grid>
                ))
              : ""}
          </Grid>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <MuiTranslateTable
                data={data.Detail}
                columns={columns}
                options={options}
                components={{
                  TableHead: CustomHeader,
                }}
              />
            </Grid>
          </Grid>
          {showAdd && (
            <PopupSettlementAr
              initialValues={data.Detail}
              unselectInvoice={unselectInvoice}
              checkSettleDate={checkSettleDate}
              code={getValues("ArNo")}
              rcpthDate={getValues("RcpthDate")}
              open={showAdd}
              save={(row) => SaveFromPopup(data, row)}
              cancel={CancelFromPopup}
              maxWidth={"xl"}
            />
          )}
        </Paper>
        {/* Withholding Tax */}
        <Paper elevation={1} style={{ marginBottom: 12 }}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
              <Typography className={classes.heading}>{translate("ra.field.Withholding Tax")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="center">
                    {formFieldsWht
                      ? formFieldsWht.map((item, idx) => (
                          <Grid item xs={item.size} key={idx} style={item.style}>
                            {React.createElement(item.field.type, {
                              ...{
                                ...item.field.props,
                                methods,
                                key: item.field.props.name,
                                onChange: CheckWhtAccount,
                              },
                            })}
                          </Grid>
                        ))
                      : ""}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1} alignItems="flex-start">
                    <Grid item xs={2}>
                      <TextField
                        label={translate("ra.field.Total")}
                        name="AmtBfWht"
                        variant="outlined"
                        margin="dense"
                        InputProps={{ inputComponent: NumberFormatInput }}
                        inputProps={{
                          style: { textAlign: "right" },
                          maxLength: 18,
                          decimal: 2,
                        }}
                        defaultValue={0}
                        value={data.AmtBfWht}
                        onChange={(e) => {
                          const total = e.target.value;
                          const rate = data.WhtRate;
                          CalculateWhtAmt(total, rate);
                        }}
                        onFocus={handleFocus}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        label={translate("ra.field.Rate")}
                        name="WhtRate"
                        variant="outlined"
                        margin="dense"
                        InputProps={{ inputComponent: NumberFormatInput }}
                        inputProps={{
                          style: { textAlign: "right" },
                          maxLength: 18,
                          decimal: 2,
                        }}
                        defaultValue={0}
                        value={data.WhtRate}
                        onChange={(e) => {
                          const total = data.AmtBfWht;
                          const rate = e.target.value;
                          CalculateWhtAmt(total, rate);
                        }}
                        onFocus={handleFocus}
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <TextField
                        label={translate("ra.field.Amount")}
                        name="WhtAmt"
                        variant="outlined"
                        margin="dense"
                        InputProps={{ inputComponent: NumberFormatInput }}
                        inputProps={{
                          style: { textAlign: "right" },
                          maxLength: 18,
                          decimal: 2,
                        }}
                        defaultValue={0}
                        value={data.WhtAmt}
                        onChange={(e) => {
                          const numSumInvAmt = ToNumber(sumInvAmt);
                          const numWhtAmt = e.target.value;
                          const numPayAmt = data.Amount;
                          const numBankChargeAmt = ToNumber(data.BankChargeAmt);
                          const numGainLoss = ToNumber(data.GainLossAmt ?? 0);
                          AdjustPaymentAmount(numSumInvAmt, numPayAmt, numWhtAmt, numBankChargeAmt, numGainLoss);
                        }}
                        disabled={!showWhtOw}
                        onFocus={handleFocus}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showWhtOw}
                            color="primary"
                            onChange={(e, newValue) => {
                              if (!newValue) {
                                const total = ToNumber(data.AmtBfWht);
                                const rate = ToNumber(data.WhtRate);
                                CalculateWhtAmt(total, rate);
                              }
                              setShowWhtOw(newValue);
                            }}
                          />
                        }
                        label={translate("ra.field.Overwrite")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Paper>
        {/* Billing To */}
        <Paper elevation={1} style={{ marginBottom: 12 }}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
              <Typography className={classes.heading}>{translate("ra.field.Billing To")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container alignItems="flex-start" spacing={1} style={{ marginBottom: 6 }}>
                {formFieldsBilling
                  ? formFieldsBilling.map((item, idx) => (
                      <Grid item xs={item.size} key={idx} style={item.style}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                            onChange: UpdateForm,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Paper>
        {/* Receipt Form */}
        <Paper elevation={1} className={classes.root}>
          <Grid container alignItems="flex-start" spacing={1} style={{ marginTop: 6, marginBottom: 6 }}>
            <Grid item xs={8}>
              <Typography className={classes.heading} style={{ marginTop: 8 }}>
                {`${translate("ra.module.Receipt")} ${translate("ra.fieldAbbr.account")}`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ marginTop: 12 }}>
                    {translate("ra.field.Payment Amount")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="Amount"
                    variant="outlined"
                    margin="dense"
                    InputProps={{ inputComponent: NumberFormatInput }}
                    inputProps={{
                      style: { textAlign: "right" },
                      maxLength: 18,
                      decimal: 2,
                    }}
                    defaultValue={0}
                    value={data.Amount}
                    disabled={data.Detail.length > 0}
                    onChange={(e) => {
                      const numInvAmt = ToNumber(sumInvAmt);
                      const numCurAmt = e.target.value;
                      const numWhtAmt = ToNumber(data.WhtAmt ?? 0);
                      const numBankChargeAmt = ToNumber(data.BankChargeAmt ?? 0);
                      //const numGainLoss = ToNumber(data.GainLossAmt ?? 0);
                      AdjustPaymentAmount(numInvAmt, numCurAmt, numWhtAmt, numBankChargeAmt);
                      SetDepositAmount(numCurAmt, data.Detail.length);
                    }}
                    onFocus={handleFocus}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1} alignItems="center">
                {formFieldsReceiptCrAccount
                  ? formFieldsReceiptCrAccount.map((item, idx) => (
                      <Grid item xs={item.size} key={idx} style={item.style}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 10 }}>
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ marginTop: 2 }}>
                    {translate("ra.field.Settlement Base Amount")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    {NumberFormat(data.BaseAmt ?? 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1} alignItems="center">
                {formFieldsGainLossAccount
                  ? formFieldsGainLossAccount.map((item, idx) => (
                      <Grid item xs={item.size} key={idx} style={item.style}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                            onChange: CheckGLAccount,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ marginTop: 2 }}>
                    {translate("ra.field.Gain/Loss Amount")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {/* <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    {NumberFormat(data.GainLossAmt ?? 0)}
                  </Typography> */}
                  <TextField
                    name="GainLossAmt"
                    variant="outlined"
                    margin="dense"
                    InputProps={{ inputComponent: NumberFormatInput }}
                    inputProps={{
                      style: { textAlign: "right" },
                      maxLength: 18,
                      decimal: 2,
                    }}
                    defaultValue={0}
                    value={data.GainLossAmt}
                    onChange={(e) => {
                      // setData((state) => ({
                      //   ...state,
                      //   GainLossAmt: e.target.value,
                      // }));
                      const numSumInvAmt = ToNumber(sumInvAmt);
                      const numWhtAmt = ToNumber(data.WhtAmt);
                      const numPayAmt = ToNumber(data.Amount);
                      const numBankChargeAmt = ToNumber(data.BankChargeAmt);
                      const numGainLoss = ToNumber(e.target.value);
                      AdjustPaymentAmount(numSumInvAmt, numPayAmt, numWhtAmt, numBankChargeAmt, numGainLoss);
                      if (numGainLoss !== 0) {
                        CheckGLAccount(numGainLoss);
                      } else {
                        methods.clearErrors("DeptGL");
                        methods.clearErrors("GainLossAcc");
                      }
                    }}
                    onFocus={handleFocus}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1} alignItems="center">
                {formFieldsWht2
                  ? formFieldsWht2.map((item, idx) => (
                      <Grid item xs={item.size} key={idx} style={item.style}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                            onChange: CheckWhtAccount,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 10 }}>
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography variant="body2"> {translate("ra.field.Withholding Tax")}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    {NumberFormat(data.WhtAmt ?? 0)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1} alignItems="center">
                {formFieldsBank
                  ? formFieldsBank.map((item, idx) => (
                      <Grid item xs={item.size} key={idx} style={item.style}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                            onChange: CheckBankAccount,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ marginTop: 12 }}>
                    {`${translate("ra.field.Bank Charge")} ${translate("ra.field.Amount")}`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="BankChargeAmt"
                    variant="outlined"
                    margin="dense"
                    InputProps={{ inputComponent: NumberFormatInput }}
                    inputProps={{
                      style: { textAlign: "right" },
                      maxLength: 18,
                      decimal: 2,
                    }}
                    defaultValue={0}
                    value={data.BankChargeAmt}
                    onChange={(e) => {
                      const numSumInvAmt = ToNumber(sumInvAmt);
                      const numWhtAmt = ToNumber(data.WhtAmt ?? 0);
                      const numPayAmt = data.Amount;
                      const numBankChargeAmt = e.target.value;
                      const numGainLoss = ToNumber(data.GainLossAmt ?? 0);
                      AdjustPaymentAmount(numSumInvAmt, numPayAmt, numWhtAmt, numBankChargeAmt, numGainLoss);
                      if (ToNumber(e.target.value) !== 0 && e.target.value !== "") {
                        CheckBankAccount(ToNumber(e.target.value));
                      } else {
                        methods.clearErrors("BankChargeDept");
                        methods.clearErrors("BankChargeAcc");
                      }
                    }}
                    onFocus={handleFocus}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={1} alignItems="center">
                {formFieldsReceiptDrAccount
                  ? formFieldsReceiptDrAccount.map((item, idx) => (
                      <Grid item xs={item.size} key={idx} style={item.style}>
                        {React.createElement(item.field.type, {
                          ...{
                            ...item.field.props,
                            methods,
                            key: item.field.props.name,
                          },
                        })}
                      </Grid>
                    ))
                  : ""}
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ marginTop: 10 }}>
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography variant="body2" style={{ marginTop: 2 }}>
                    <b>Net Payment</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    <b>{NumberFormat(data.NetBaseAmt)}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={4}>
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={6} style={{ marginTop: 6 }}>
                  <Typography variant="body2">Credit Available</Typography>
                </Grid>
                <Grid item xs={6} style={{ marginTop: 6 }}>
                  <Typography variant="body1" align="right" style={{ marginRight: 14 }}>
                    {NumberFormat(data.AvlCrBaseAmt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <pre>{process.env.NODE_ENV === "development" ? JSON.stringify(data, 0, 2) : ""}</pre>
        <ButtonFooter CancelFnc={CancelFnc} />
      </form>

      <NavRight
        open={openDim}
        close={() => setOpenDim(false)}
        ShowDim={() => ShowDim()}
        module={"AR_Receipt"}
        moduleId={id}
      />
    </div>
  );
};

export default Edit;
